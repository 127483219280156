@import url("https://fonts.googleapis.com/css?family=Montserrat");

$hopeful-color: rgb(215, 237, 50);
$red: #e42869;
$pink: #fff3ec; 
$pink2: #ffdbdb00; 

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


body {overflow-x: hidden}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.action-item-list {
  margin: 20px;
  margin-top: 0px;
  display: grid;

  button {
    border-width: 1px;
    border-style: none;
    border-color: #282c34;
    p {
      font-size: 1.5em;
    }
  }

}


@font-face {
  font-family: 'Inconsolata';
  src: url('fonts/Inconsolata-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter-VariableFont_slnt,wght.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Now';
  src: url('fonts/now/Now-Medium.otf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Forque';
  src: url('fonts/Forque-M3An.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Evil';
  src: url('fonts/EvilEmpire-4BBVK.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FG';
  src: url('fonts/Franklin\ Gothic\ No.\ 2\ Roman.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'jetbrains';
  src: url('fonts/JetBrainsMono[wght].ttf');
  font-weight: normal;
  font-style: normal;
}

html {
  background: #ffffff;//linear-gradient(308deg, rgb(253, 223, 207) 100%, rgb(255, 102, 181) 0%);
  height: 100vh;
}

.app-header {
  font-family: 'jetbrains';
  // letter-spacing: .1em;
  height: fit-content;
  display: block;
  .row {
    display: flex;
  }
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  // font-weight: bold;
  font-size: 1.3em;
  padding: 20px;
  border-bottom-style: solid;
  border-bottom-color: #000000;
  border-width: 2px;
  align-items: center;
  background-color: #e42869;
  .top {
    position: absolute;
    top: 0;
  }
  
}

@media (max-width: 500px) {
  .app-header {
    justify-content: center;
  }
}

.review-bomb {
  p {
    background-color: transparent;
    text-decoration: underline;
  }
}

.option {
  &:visited {
    color: Black;
  }
  color: black;
  text-decoration: none;
  text-align: right;
  font-size: .9em;
  padding-top: 10px;
  // border-bottom-style: solid;
  // border-bottom-width: 2px;
  // border-bottom-color:#ffffff;
  background-color: #fec0c2;
  width: max-content;
  display: block;

  margin-bottom: 5px;

  &:hover {
    background-position: -0% 0;
  }
  background-size: 200% auto;
  background-image: linear-gradient(to left, rgba(255,255,255,0) 50%, #ffffff 50%);
  background-position: -99.99% 0;
  transition: background-position 0.5s ease-out;
  margin-left: auto;
}

h1,h2 {
  font-family: 'jetbrains';
}

.item {
  font-family: 'jetbrains';
  margin: auto;
  width: fit-content;
  padding: 10px;
  font-size: 1.1em;
  border-radius: 20px;
  margin-bottom: 20px;
  &:hover {
    background-color: $pink;
  }

  text-decoration: none;
  color: black;
  background-color: $pink2;
  border-color: $red;
  border-width: 3px;
  max-width: 400px;
  p {
    font-size: .7em;
    border-radius: 40px;
    background-color: transparent;
  }
}

.rate {
  background-color: #b2cf1f;
}

.dto {
  background-color: #feb117;
}

.prop-item {
  background-color: #fe7b7b;
}

.prop {
  img {
    max-width: 200px;
    width: 45%;
  }
  
}

.navbar-options {
  margin-top:50px;
  margin-left: auto; 
  margin-right: 0;
  
}

@media (min-width: 1400px) {
  .navbar-options {
    display: flex;
    flex-direction: row; /* Switch to horizontal layout */
    .option {
      margin-right: 40px;
      font-size: 1.2em;
      padding-bottom: 0px;
      height: fit-content;
    }
  }
}

p {
  // font-family: 'Inter';
  background-color: white;
  padding: 20px;
  font-size: 1em;
  width: 80%;
  margin: auto;
  max-width: 800px;
}

.about-us {
  font-family: 'jetbrains';
  h1 {

  }


}

.popup-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}


.RYL {
  font-family: 'jetbrains';
  // border-radius: 30px;
  border-style: none;
  background-color: #dafb36;
  padding: 10px;
  padding-top:15px;
  max-width: 600px;
  margin: auto;
  font-size: .6em;
  color:#000000bb;
  h1 {
    padding: 0px;
    margin: 0px;
  }
  img {
    max-height: 100px;
    margin: 10px;
  }
  &:hover {
    background-color: #a4c500;
  }
  p {
    font-size: 2em;
  }

  border-radius: 20px;


}

.VOTE {
  
  img {
    border-radius: 50px;

    max-width: 100px;
    margin: 10px;
  }
  width: 100%;
  max-width: 600px;
  margin: auto;
  border-style: none;
  background-color: #3c7eff;
  padding: 10px;

  font-family: 'Inter';
  color: #fbe100;
  font-weight: 900;
  letter-spacing: 0.35rem;
  text-transform: uppercase;
  font-size: 1.1em;

  .lets {
    // color: rgb(0, 0, 0);
    margin-bottom: 5px;
  }

  .vote {
    font-size: 1.2em;
    font-weight: bolder;
    color: black;
  }

  .vote-date {
    color: white;
    font-size: .7em;
  }

  &:hover {
    background-color: #006cb5;

  }
}


// .JOIN {
//   background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgb(118, 174, 238) 100%);
//   font-size: .7em;
//   padding: 10px;
//   &:hover {
//     background: radial-gradient(circle, rgb(255, 209, 229) 0%, rgba(148, 187, 233, 1) 100%);
//   }
// }

.org-join {
  font-family: 'jetbrains';
  text-align: center;
  //background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
  padding: 10px;
  max-width: 600px;
  display:grid;
  p {
    font-size: 1.5em;
    // font-weight: bold;
  }
  margin: auto;
  input {
    padding: 10px;
    margin: auto;
    border-radius: 5px;
    margin: auto;
    margin-top: 10px;
    border-style: none;
    text-align: center;
    max-width: 400px;
    font-size: 1.4em;
  }

  button {
    border-style: none;
    margin: auto;
    margin-top: 10px;
    
    font-size: 1.3em;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    background: linear-gradient(80deg, rgb(255, 123, 123) 0%, rgb(255, 50, 152) 100%);
    border-radius: 5px;
    color: white;
    max-width: 600px;
    &:disabled {
      background: gray;
    }
  }


  

  .small-text {
    font-size: 1em;
  }
}

.flex {

}

.help-box {
  margin-bottom: 40px;
}

.help {
  margin: auto;
  button {
    border-style: none;
    background: linear-gradient(80deg, rgb(255, 123, 123) 0%, rgb(255, 50, 152) 100%);
    border-radius: 200px;
    padding: 30px;
    color: rgb(0, 0, 0);

    font-family: 'Now';
    max-width: 600px;
    font-size: 1.5em;
  }
  width: 100%;
  

  margin-bottom: 20px;

  button.selected {
    background-color: rgb(175, 218, 32);
  }
}


.jump-to-bottom-wrapper {
  position: fixed;
  text-align:center;

  button {
    width: 100%;
  position: fixed;
  bottom: 0px;
  // color: $gray;
  background-color: #ff71b4;
  // border-color: $orange;
  border-style: none;
  font-size: 1.2em;
  padding-top: 5px;
  padding-bottom: 4px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  letter-spacing: .14em;
  font-weight: bold;
  padding-right: 0px;
  padding-bottom: 5px;
  }
}


@keyframes jump-up-animation {
  from {
    bottom: -100px;
  }

  to {
    bottom: 0px;
  }
}

@keyframes jump-down-animation {
  from {
    bottom: 0px;
  }

  to {
    bottom: -100px;
  }
}

.show-jump {
  animation: jump-up-animation .4s ease-in;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.hide-jump {
  animation: jump-down-animation .4s ease-in;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.flex-container {
  display: flex;
  margin: auto;
  max-width: 900px;
}

.flex-child {
  flex: 1;
}

.logo {
  position: relative;
  img{
    left: 0px;
    top: 0px;
    width: 220px;
    max-width: 350px;
    position: absolute;
  }
  h1 {
    position: absolute;
    left: 0px;
    top: 6%;
    font-size: 1.1em;
    width: 220px;
    max-width: 350px;
    color: white;
    letter-spacing: .1em;
  }
  max-width: 50%;
}

.logo-text {
  // font-family: 'FG';
  color: rgb(0, 0, 0);
  font-size: 2.5em;
  padding-left: 10px;
  padding-top: 140px;
  letter-spacing: .2em;
  .renters {
    font-size: 1.1em;
  }
  .action {
    color: transparent;
    -webkit-text-stroke: 2px #000000;
    letter-spacing: .1em;
    margin: -10px;
    font-size: 1.4em;
  }
}

.PDX {
  font-family: 'Now';
  font-size: 2.0em;
  color: rgb(0, 83, 68);
}

.skills-container {
  width: 90%;
  margin: auto;
  padding-bottom: 20px;
  border-radius: 30px;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 0px;
}

.small {
  font-size: .8em;
}

.join {
  font-size: .8em;
  font-family: 'jetbrains';
  color: #000000;
  width: 90%;
  max-width: 400px;
  margin: auto;
  padding-top: 10px;
  border-radius: 20px;
  border-style: solid;
  h3 {
    color:#000000;
    font-size: 1.8em;
    margin-bottom: 0px;
  }
  h4 {
    width: 80%;
    margin: auto;
    margin-top: 4px;
    font-size: 1.4em;
    padding: 5px;
    font-weight: normal;
  }
  margin-bottom: 20px;
  p {
    font-size: 1.3em;
    background-color: transparent;
  }
  background: rgb(255,179,179);
  background: linear-gradient(353deg, rgba(255,179,179,1) 0%, rgba(185,230,255,1) 100%); 
}

.DTO {
  p {
    font-family: 'jetbrains';
  }
}

.skill {
  flex-grow: 4;
  font-size: 1.4em;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  display: block;
  margin-left: 5px;
  margin-right: 5px;
  font-family: "jetbrains";
  background-color: rgb(255, 255, 255);
  border-style: solid;
  border-color: #00000070;
  color: rgb(14, 14, 14);
  &.selected {
    background-color: $red;
    color: rgb(255, 255, 255);
  }
}

.skill-span {
  margin-right: 10px;
}

.gelatine {
  font-family: 'jetbrains';
  animation: gelatine 0.5s forwards;
}
@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(1.1, 1.1); }
  50% { transform: scale(0.9, 0.9); }
  75% { transform: scale(1.05, 1.05); }
}

.spiel-container {
  background: rgb(195,34,93);
  background: linear-gradient(147deg, rgba(195,34,93,1) 0%, rgba(229,112,41,1) 100%);
  width: fit-content;
  height: fit-content;
  margin: auto;

  padding: 5px;
  margin-bottom: 20px;
}

.spiel {
  line-height: 1.6;
  background-color: #000000;
    width: fit-content;
  margin-bottom: 30px;
  padding: 10px;
  margin: 10px;
  font-size: 1.1em;
  font-family: 'Now';
  color: rgba(255, 255, 255, 0.789);
  span {
    color:  rgb(255, 63, 63);
    font-weight: bold;
  }

  .hope {
    color:$hopeful-color;
  }
}

.textarea {
  
  resize: none;
  margin: auto;
  margin-top:10px;
  font-size: 1.6em;
  background-color: $pink;
  border-style: none;
  border-radius: 20px;
  padding: 10px;
  max-width: 90%;

}

.char-counter {
  margin-top: 5px;
  font-weight: bold;
  position: absolute;
  right: 12%;
  bottom: 5%;
  color: black;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  border-radius: 20px;
  opacity: 50%;
}

.textarea-container {
  position: relative;
  padding-bottom: 10px;
  max-width: 100vw;

}

.projects {
  font-family: 'Now';
  color: orange;
  font-size: 1.4em;
}

.actions-container {
  padding-top: 20px;
  width: 100%;
  margin:auto;
}

.explainer {
  color: rgba(255, 255, 255, 0.783);
  padding: 20px;
  max-width: 60%;
  margin: auto;
  font-family: 'Inter';
}

.fist {
  left: 0px;
  z-index: -1;
  width: 100%;
  position: absolute;

  width: 100%; /* Adjust width as needed */
  height: auto;
  opacity: 0.5; /* Controls the fade-out effect; lower values make it more transparent */
  filter: opacity(30%) contrast(200%); /* Optionally adjust brightness for the faded effect */ 
  scale: 1.5;
  overflow: hidden;
}

.join-us-button {
  font-family: 'jetbrains';
  background-color: white;
  font-size: 1.5em;

}

.goals {
  font-family: 'jetbrains';
  ul {
    width: fit-content;
    margin: auto;
    text-align: left;
    align-items: start;
    display: block;
  }
  padding-bottom: 20px;
}

.join-anchor {
  border-style: none;
  border-top-style: solid;
  border-color: black;
  font-family: 'jetbrains';
  padding: 10px;
  position: fixed;
  bottom: 0px;
  font-size: 1.8em;
  background: rgb(255,179,179);
  width: 100%;
  background: linear-gradient(353deg, rgba(255,179,179,1) 0%, rgba(185,230,255,1) 100%); 
  left: 0px;
}

.good-at {
  font-style: italic;
}

.center-img {
  max-width: 300px;
}

.Collapsible__trigger {
  font-family: 'jetbrains';
  font-size: 1.3em;
  font-weight: bold;
  background-color: rgb(194, 255, 133);
  display: block;
  padding: 5px;
  margin: 5px;
  border-radius: 20px;
}


.Collapsible__contentInner {
  margin: 5px;
  p {
    background-color: rgb(255, 219, 181);
  }
}

.dto-questions {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px;
  label {
    margin-bottom: 10px;
  }
}

.italic {
  font-style: italic;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
  margin: 10px;
}

/* Might want to wrap a span around your checkbox text */
.checkboxtext
{
  /* Checkbox text */
  font-size: 110%;
  display: inline;
}

.what-is {
  margin-top: 0px;
  padding-top: 0px;
}

.dto-p {
  margin-top: 0px;
  padding-top: 0px;
}

.contact-options {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 10px;
  img {
  max-width: 30px;
  }
}

.upcoming-events {
  p {
    width: fit-content;
    border-style: solid;

  }
  img {
    width: 80%;
    max-width: 300px;
  }
}

.email {
  border-style: none;
  background-color: transparent;
  padding: 0px;
  offset: 10px;
  position: relative;
  img {
    position: absolute;
    left: 0px;
    top: -35px;
    max-width: 40px;
  }
}

.Collapsible__trigger {
  max-width: fit-content;
  margin: auto;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.Collapsible__contentInner {
  p {
    background-color: #8ccce3a0;
    justify-content: left;
    text-align: left;
    border-radius: 20px;
    border-style: solid;
    border-width: 1.5px;
    border-color:#ffffff;
    margin: auto;
    margin-top: 3px;

  }
  margin-bottom: 20px;
}

.ten-pt {
  ol {
    width: fit-content;
    margin: auto;
    h3 {
      width: fit-content;
      margin: auto;
    }
  }
}

.email-modal {
  margin: auto;
  width: fit-content;
  font-size: 2em;
  text-align: center;
  border-radius: 10px;
  p {
  background-color: rgb(255, 255, 255);
  }
  box-shadow: #282c34 0px 10px 10px;
}

.upcoming {
  // border-style: dashed;
  border-color: rgb(111, 183, 3);
  // background-color:#006cb5;
  width: fit-content;
  margin: auto;
  margin-top: 10px;
  padding: 10px;
  .upcoming-event {
    width: 500px;
    max-width: 100%;
  }
  p {
    padding-top: 5px;
  }
}

.unions-container {
  align-items: center;
  justify-content: center;
  h3 {
    color: black;
    text-decoration: none;
  }
  img {
    max-width: 200px;
    width: 50%;
  }
}

.tenant-union-page {
  background-color: rgb(255, 205, 105);
}

.slides {
  img {
    max-width: 500px;
    width: 100%;
    margin: auto;
  }
  justify-content: center;
}

.review-links {
  a {
    display: block;
    color: black;
    // font-weight: bold;
    background-color: orange;
    margin:auto;
    width: fit-content;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 5px;
    text-decoration: none;
    font-family: 'Now';
  }
}

.review-scripts {
  margin: auto;
  max-width: 600px;

  li {
    display:list-item;
    border-style: dotted;
    border-radius: 30px;
    padding: 5px;
    margin-bottom: 20px;
    background-color: rgb(255, 232, 169);
  }
}

.tu-logo {
  border-radius: 50%;
  margin-top: 10px;
  max-width: 100px;
}